<template>
       <!-- Footer -->
   <footer class="iq-footer1" style="text-align: left; height: 11vh;">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <!-- <div class="player row"> -->
              
              <img class="footer-logo img-fluid mb-2" src="../../assets/images/SudaniWhiteLogo-NoShadow.png" alt="footer logo">
              <!-- <li class="d-inline navbar-text"> {{ year }} , Made by <a href="https://www.byte.sd/">Byte</a></li>  -->
               <!-- </div> -->
            </div>
         </div>
      </div>
   </footer>
   <!-- Footer END -->
</template>

<script>
export default {

}
</script>

<style>

.iq-footer1 {
  background: linear-gradient(180deg,#d8cdb1 0,#5B5137 );
z-index: 9;
position: fixed;
width: 100%;
bottom: 0;
}

.footer-logo {
   width: 23vw;
margin-left: 2vh;
margin-top: 2vh;
}

/* @media (min-width:1440px){
  .footer-logo {
    width: 10%;
    margin-left: 5%;
    margin-top: 1%;
}

}
@media (min-width:1200px){
  .footer-logo {
    width: 10%;
    margin-left: 5%;
    margin-top: 1%;
}

} */
@media (min-width:900px){
  .footer-logo {
    width: 10%;
}

}
@media (min-width:768px){
  .footer-logo {
    width: 10vw;
    margin-left: 2vh;
    margin-top: 2vh;
}
}
@media (width:360px){
  .footer-logo {
    width: 6vh;
    margin-left: 2vh;
    margin-top: 2vh;
}

}
</style>