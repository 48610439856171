<template>
  <div class="wrapper">
    <AppSidebar />
    <Suspense>
      <template #default>
        <div id="content-page" class="content-page">
          <HomePage />
        </div>
      </template>
      <template #fallback>
        <AppLoading/>
      </template>
    </Suspense>
  </div>
  <AppFooterHome />
</template>

<script>
import { Suspense } from "vue";
import AppSidebar from "@/components/layouts/AppSidebar.vue";
import HomePage from "@/components/Pages/HomePage.vue";
import AppFooterHome from "@/components/layouts/AppFooterHome.vue";
import AppLoading from "@/components/Spinners/AppLoading.vue";
export default {
  name: "Home",
  components: {
    Suspense,
    AppSidebar,
    HomePage,
    AppFooterHome,
    AppLoading
  },
};
</script>
