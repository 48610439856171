import { HTTP } from '@/Api/http-common';
const catogerys = {
    namespaced: true,
    state: {
        catogerys: []
    },
    mutations: {
        SET_CATOGERYS(state, data) {
            state.catogerys = data
        }
    },
    actions: {
        CatogerysAll({commit}) {
            HTTP.get('getCatogerys.php').then(res => {
                commit('SET_CATOGERYS', res.data.getPost)
            }).catch(error => console.error(error))
        },
    },
} 

export default catogerys