<template>
  <div class="row row--grid">
    <div class="col-lg-12">
        <div class="iq-card">
            <div class="iq-card-header d-flex justify-content-between align-items-center">
                <div class="iq-header-title">
                    <h4 class="card-title">الـــهداية</h4>
                </div>
              <div id="feature-album-slick-arrow" class="slick-aerrow-block"></div>
          </div>
          <div class="iq-card-body">
            <vueper-slides 
              rtl
              class="no-shadow"
              :visible-slides="4"
              slide-multiple
              :gap="3"
              :slide-ratio="1 / 4"
              :dragging-distance="200"
              :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }">
              <vueper-slide v-for="publish in carousel.slice(0,6)" :key="publish.id" :image="publish.img" :title="publish.desc_ar" @click="getFeed(publish)" />
              <vueper-slide v-for="publish in carousel.slice(0,3)" :key="publish.id" :image="publish.img" :title="publish.desc_ar" @click="getFeed(publish)" />
            </vueper-slides>
          </div>
        </div>
    </div>
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="iq-header-title">
            <h4 class="card-title font-weight-600 FontFamily"></h4>
          </div>
        </div>
        <div class="iq-card-body">
          <ul class="list-unstyled row iq-box-hover mb-0">
            <li
              class="col-6 col-sm-4 col-lg-3 col-xl-3 iq-music-box"
              v-for="post in catogerys" :key="post.id"
            >
              <div class="iq-card">
                <div class="iq-card-body p-0">
                  <div class="iq-thumb">
                    <div class="iq-music-overlay"></div>
                    <!-- <a href="music-player.html"> -->
                    <!-- <a @click="getFeed(play)"> -->
                      <img
                        v-lazy="post.coverimage"
                        class="img-border-radius img-fluid w-100"
                        alt=""
                      />
                    <!-- </a> -->
                    <!-- </a> -->
                    <div class="overlay-music-icon">
                      <a @click="getFeed(post)">
                        <i class="las la-play-circle"></i>
                      </a>
                    </div>
                  </div>
                  <div class="feature-list text-center">
                    <h6 class="font-weight-600 mb-0 FontFamily">
                      {{ post.name_ar }}
                    </h6>
                    <p class="mb-0 FontFamily">{{ post.name_en }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router"
import { mapState } from "vuex";
import { useCookie } from 'vue-cookie-next';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import { ref } from '@vue/reactivity';
export default {
    components: { VueperSlides, VueperSlide },
    computed: {
     ...mapState('catogerys', ['catogerys'])
    },
    created() {
      this.$store.dispatch('catogerys/CatogerysAll');
    },
   async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const carousel = ref([]);
      try {
        await HTTP.get('getPost.php?LIMIT=9&OFFSET=0').then((res) => {
            carousel.value = res.data.getPost; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (post) => {
          cookie.setCookie('cat_id', post.catogeryId)
          router.push({ name: "Catogerys", params: { id: post.catogeryId  } });
        };    
      return {
        getFeed, carousel
      };
    },
}
</script>

<style>
.vueperslide {
 white-space:normal;
 background-size:cover;
 flex-shrink:0;
 display:block;
 width:100%;
 position:relative
}
.vueperslide--clone-1 {
 position:absolute;
 top:0;
 bottom:0;
 right:100%
}
.vueperslides--rtl .vueperslide--clone-1 {
 right:auto;
 left:100%
}
.vueperslide[href] {
 -webkit-user-drag:none
}
.vueperslide__image {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 background-size:cover
}
.vueperslide__loader {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 height:100%;
 margin:auto
}
.vueperslides--fade .vueperslide {
 position:absolute;
 top:0;
 left:0;
 right:0;
 bottom:0;
 opacity:0;
 transition:ease-in-out opacity;
 transition-duration:inherit
}
.vueperslides--fade .vueperslide--active,
.vueperslides--fade .vueperslide--visible {
 z-index:1;
 opacity:1
}
.vueperslides--slide-image-inside .vueperslide {
 overflow:hidden
}
.vueperslides--3d .vueperslide {
 position:absolute;
 z-index:-1;
 height:100%
}
.vueperslides--3d .vueperslide--previous-slide,
.vueperslides--3d .vueperslide--active,
.vueperslides--3d .vueperslide--next-slide {
 z-index:0
}
.vueperslides--3d .vueperslide--active {
 z-index:1
}
.vueperslides--3d .vueperslide[face=front] {
 transform:rotateY(90deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=right] {
 transform:rotateY(90deg) translate(50%);
 transform-origin:100% 0
}
.vueperslides--3d .vueperslide[face=back] {
 transform:rotateY(270deg) translate(-50%) rotateY(-90deg)
}
.vueperslides--3d .vueperslide[face=left] {
 transform:rotateY(270deg) translate(-50%);
 transform-origin:0 0
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 content:"";
 position:absolute;
 bottom:100%;
 left:-1em;
 right:-1em;
 height:2em;
 box-shadow:0 0 20px #00000040;
 z-index:2
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after {
 top:100%;
 bottom:auto
}
.vueperslides__arrows {
 color:#fff
}
.vueperslides__arrows--outside {
 color:currentColor
}
.vueperslides__arrow {
 top:50%;
 background-color:transparent;
 border:none;
 opacity:.7
}
.vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrow--next {
 right:auto;
 left:.5em
}
.vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrow--prev {
 left:auto;
 right:.5em
}
.vueperslides__arrow:hover {
 opacity:1
}
.vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next {
 right:auto;
 left:-3.5em
}
.vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev {
 left:auto;
 right:-3.5em
}
.vueperslides__paused {
 top:.7em;
 right:.7em;
 opacity:0;
 text-shadow:0 0 3px rgba(0,0,0,.4);
 z-index:1
}
.vueperslides:hover .vueperslides__paused {
 opacity:1
}
.vueperslides__bullets:not(.vueperslides__bullets--outside) {
 color:#fff
}
.vueperslides__bullet {
 margin:1.5em .6em;
 padding:0;
 border:none;
 background:none
}
.vueperslides__bullet .default {
 width:12px;
 height:12px;
 border-radius:12px;
 border:1px solid currentColor;
 background-color:transparent;
 box-shadow:0 0 1px #00000080,0 0 3px #0000004d;
 transition:.4s ease-in-out;
 box-sizing:border-box
}
.vueperslides__bullet .default span {
 display:none
}
.vueperslides__bullet--active .default {
 border-width:6px
}
.vueperslide,
.vueperslide__image {
 background-position:center
}
.vueperslide__video {
 outline:none
}
.vueperslide--no-pointer-events:before {
 content:"";
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 right:0
}
.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
 display: flex;
flex: 1 1 auto;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
background: #826F46BD;
height: 40px;
bottom: 0px;
position: absolute;
width: 100%;
/* border: 2px solid #5375AC; */
font-size: 16px;
color: #fff;
}
.vueperslide__content-wrapper.parallax-fixed-content,
.vueperslide--has-image-inside .vueperslide__content-wrapper,
.vueperslide--has-video .vueperslide__content-wrapper {
 position:absolute;
 z-index:2;
 top:0;
 bottom:0;
 left:0;
 right:0;
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 pointer-events:none
}
.vueperslides {
 position:relative;
 min-height: 25vh;
}
.vueperslides--fixed-height .vueperslides__inner,
.vueperslides--fixed-height .vueperslides__parallax-wrapper,
.vueperslides--fixed-height .vueperslide {
 height:inherit
}
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
 padding-bottom:0!important
}
.vueperslides--fixed-height.vueperslides--bullets-outside {
 margin-bottom:4em
}
.vueperslides__inner {
 position:relative;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 min-height: 25vh;
}
.vueperslides__parallax-wrapper {
 position:relative;
 overflow:hidden;
 min-height: 35vh;
}
.vueperslides--3d .vueperslides__parallax-wrapper {
 overflow:visible
}
.vueperslides__track {
 position:absolute;
 top:0;
 height:100%;
 left:0;
 right:0;
 overflow:hidden;
 z-index:1;
 border-radius: 12px
}
.vueperslides--parallax .vueperslides__track {
 height:200%;
 transform:translateY(0)
}
.vueperslides--touchable .vueperslides__track {
 cursor:ew-resize;
 cursor:-webkit-grab;
 cursor:grab
}
.vueperslides--touchable .vueperslides__track--mousedown,
.vueperslides--touchable .vueperslides__track--dragging {
 cursor:-webkit-grabbing;
 cursor:grabbing
}
.vueperslides--3d .vueperslides__track {
 overflow:visible;
 perspective:100em
}
.vueperslides__track-inner {
 white-space:nowrap;
 transition:.5s ease-in-out transform;
 height:100%;
 display:flex
}
.vueperslides--no-animation .vueperslides__track-inner {
 transition-duration:0s!important
}
.vueperslides--fade .vueperslides__track-inner {
 white-space:normal;
 transition:none
}
.vueperslides--3d .vueperslides__track-inner {
 transform-style:preserve-3d
}
.vueperslides__track--mousedown .vueperslides__track-inner {
 transition:.25s ease-in-out transform!important
}
.vueperslides__track--dragging .vueperslides__track-inner {
 transition:none
}
.vueperslides__arrow {
 position:absolute;
 font-size:inherit;
 color:inherit;
 text-align:center;
 transform:translateY(-50%);
 transition:.3s ease-in-out;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 line-height:1
}
.vueperslides__arrow svg {
 vertical-align:middle;
 stroke:currentColor;
 fill:none;
 width:3.5em;
 padding:1em;
 stroke-width:1;
 transition:.3s ease-in-out;
 box-sizing:border-box;
  display: none
}
.vueperslides__arrow svg:hover {
 stroke-width:1.3
}
.vueperslides__paused {
 position:absolute;
 transition:.3s ease-in-out
}
.vueperslides__bullets {
 display:flex;
 justify-content:center;
 position:absolute;
 bottom:0;
 left:0;
 right:0
}
.vueperslides__bullets--outside {
 position:relative
}
.vueperslides__bullets button,
.vueperslides__bullet {
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 user-select:none;
 outline:none;
 z-index:2;
 display:flex;
 justify-content:center;
 align-items:center;
 color:inherit
}
.vueperslides__bullets button::-moz-focus-inner,
.vueperslides__bullet::-moz-focus-inner {
 border:0
}
.vueperslides__fractions {
 position:absolute;
 top:.8em;
 left:.5em;
 z-index:2;
 padding:.2em 1em;
 border:1px solid rgba(255,255,255,.5);
 border-radius:2em;
 background:rgba(255,255,255,.2);
 color:#fff
}
.vueperslides__progress {
 position:absolute;
 top:0;
 left:0;
 right:0;
 z-index:2;
 height:6px;
 color:#000000b3
}
.vueperslides__progress>* {
 position:absolute;
 top:0;
 bottom:0;
 left:0;
 background:currentColor;
 transition:.3s ease-in-out
}
@media(max-width:768px){
.vueperslides__bullets {
        top: 100%;
}
}
</style>