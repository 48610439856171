import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../components/Pages/Test.vue'

const routes = [
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/catogerys/:id',
    name: 'Catogerys',
    component: () => import(/* webpackChunkName: "catogerys" */ '../views/Page/Catogerys.vue'),
    props: true
  },
  {
    path: '/music-player/:id',
    name: 'MusicPlayer',
    component: () => import(/* webpackChunkName: "catogerys" */ '../views/Page/MusicPlayer.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
